module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Avond Cock-Up","short_name":"avondcockup","start_url":"/","background_color":"rgb(228,237,243)","theme_color":"rgb(228,237,243)","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8724e5663c498db75732a5ad7304f007"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/steven/Projects/AvondCockup/git/frontend/src/intl","languages":["nl","fr","en"],"defaultLanguage":"nl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-social9-socialshare/gatsby-browser.js'),
      options: {"plugins":[],"content":"4868299eb9d547e186e67a82e8503e59","async":true,"defer":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
