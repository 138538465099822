// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-articles-a-tale-of-two-protests-tsx": () => import("./../../../src/articles/a-tale-of-two-protests.tsx" /* webpackChunkName: "component---src-articles-a-tale-of-two-protests-tsx" */),
  "component---src-components-clarification-tsx": () => import("./../../../src/components/clarification.tsx" /* webpackChunkName: "component---src-components-clarification-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coronapass-tsx": () => import("./../../../src/pages/coronapass.tsx" /* webpackChunkName: "component---src-pages-coronapass-tsx" */),
  "component---src-pages-curfew-tsx": () => import("./../../../src/pages/curfew.tsx" /* webpackChunkName: "component---src-pages-curfew-tsx" */),
  "component---src-pages-early-tsx": () => import("./../../../src/pages/early.tsx" /* webpackChunkName: "component---src-pages-early-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

